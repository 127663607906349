@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '../styles/variables';
@import '../styles/utils';
@import '../styles/fullcalendar';

$base-color: rgb(235, 53, 53);




.fc-button-primary:focus {
    box-shadow: none;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fc-toolbar,
.fc-view-container {
    z-index: 0;
}

.fc-day-grid-event {
    border-color: transparent;
    padding: 4px;
    margin: 2px;
    font-weight: bold;

}

.fc-day-grid-event .fc-time {
    font-weight: bold;
}

#sidebar-overlay {
    display: none;
}

// .availability-1 {
//     background-color: rgb(235, 53, 53);
// }

// .availability-2 {
//     background-color: rgb(235, 126, 53);
// }

// .availability-3 {
//     background-color: rgb(208, 235, 53);
// }

// .availability-4 {
//     background-color: rgb(232, 235, 53);
// }

// .availability-5 {
//     background-color: rgb(135, 235, 53);
// }

// .availability-6 {
//     background-color: rgb(53, 235, 62);
// }

.bodyWrapper {
    clear: both;
}

.loginBlock {
    font-size: 12px;
    font-weight: bold;
    float: right;
    margin: 10px 16px 0 15px;
}

.primarySiloButton {
    line-height: 1.5;
    border-radius: 0.25em;
    padding: 0.5em;
    color: $primaryColor;
    background-color: transparent;
    border: 1px solid $primaryColor;
    transition: opacity 0.2s ease;
    border-radius: 3px;
    outline: 0;
    font-size: 12px;
    font-weight: bold;

    &--full {
        text-align: center;
        width: 100%;
    }
}