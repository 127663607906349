// Rotate spin animation

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

// Shake animation

@keyframes shake {
    0% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(5px);
    }

    50% {
        transform: translateX(-5px);
    }

    75% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0px);
    }
}

// Form input common styles
@mixin formInput {
    transition: box-shadow 0.2s ease;
    width: 100%;
    padding: 15px 25px;
    margin: 0;
    border: 0px solid $borderColor;
    font-size: 1em;
    box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0);
    text-align: left;
    box-sizing: border-box;
    line-height: 1.5em;
    font-family: $fontFamily;
    color: $textColor;
    overflow: auto;
    border-bottom: 1px solid $borderColor;

    &:focus {
        outline: 0;
        box-shadow: inset 0px 0px 1px 1px $primaryColor;
    }

    &.hidden {
        display: none;
    }
}