@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

body {
  background: #f8f7f5; }

.contentBlock .waitlistBlock {
  float: left;
  padding-top: 16px;
  padding-left: 15px;
  font-size: 12px; }
  .contentBlock .waitlistBlock a {
    padding: 7px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background: white;
    color: black;
    text-decoration: none; }
    .contentBlock .waitlistBlock a b {
      color: #CE9B6C; }

div[class*='-control'] {
  border-color: #CCCCCC;
  box-shadow: 0 0 0 0px #CE9B6C; }

div[class*='-control']:hover {
  border-color: #CE9B6C;
  box-shadow: 0 0 0 1px #CE9B6C; }

div[class*='-control']:hover {
  border-color: #CE9B6C;
  box-shadow: 0 0 0 1px #CE9B6C; }

div[class*='-option']:hover {
  background-color: #CE9B6C; }

.unlogged .logoWrapper {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px; }
  .unlogged .logoWrapper img {
    width: 150px; }

.unlogged .loginDetails {
  text-align: center;
  margin: auto;
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #aaa; }

.unlogged .firebaseui-container {
  padding: 40px;
  border-radius: 4px;
  background: white;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1); }

.mdl-card .is-focused label:after {
  background-color: #CE9B6C !important; }

.mdl-card .firebaseui-id-secondary-link {
  color: #CE9B6C !important; }

.mdl-card .firebaseui-id-submit {
  background-color: #CE9B6C !important; }

.bodyWrapper .loading {
  text-align: center;
  margin: auto;
  margin-top: 30px;
  font-weight: bold;
  color: rgba(45, 45, 45, 0.368627);
  font-size: 12px; }

.bodyWrapper .iconWrapper {
  margin-top: 20px;
  paddin-top: 20px; }

.bodyWrapper .details {
  margin-top: 30px;
  line-height: 18px;
  padding-top: 30px;
  max-width: 300px;
  margin: auto; }

.rotatesvg svg {
  stroke-dasharray: 0%;
  stroke-dashoffset: 100%;
  transform: rotateY(0turn);
  -webkit-transform: 50% 50%;
  transform: 50% 50%;
  -webkit-animation: flip 2s ease-in infinite;
          animation: flip 2s ease-in infinite;
  margin: auto; }
  .rotatesvg svg.done {
    -webkit-animation: none;
            animation: none; }

@-webkit-keyframes dash {
  to {
    stroke: black;
    stroke-dasharray: 0%;
    stroke-dashoffset: 0; } }

@keyframes dash {
  to {
    stroke: black;
    stroke-dasharray: 0%;
    stroke-dashoffset: 0; } }

@-webkit-keyframes flip {
  to {
    -webkit-transform: rotateY(1turn);
            transform: rotateY(1turn); } }

@keyframes flip {
  to {
    -webkit-transform: rotateY(1turn);
            transform: rotateY(1turn); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

.SideBarConfirm {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  text-align: center;
  border-top: 1px solid #CCCCCC; }
  .SideBarConfirm .SideBarButtonWrapper {
    background: #FBFBFB;
    border-top: 1px solid #CCCCCC;
    padding: 5px;
    padding-top: 8px;
    padding-bottom: 8px; }
  .SideBarConfirm .SideBarTextAreaWrapper {
    padding: 5px; }
  .SideBarConfirm textarea {
    -webkit-transition: height .3s ease-in;
    transition: height .3s ease-in;
    margin: 0;
    font-size: 13px;
    height: 60px;
    resize: none;
    width: 190px;
    outline: none;
    border: 0; }

div[role='navigation'] > div {
  overflow: hidden; }

.SidebarContentWrapper.focusTextarea .SidebarBlocks {
  bottom: 250px; }

.SidebarContentWrapper.focusTextarea .SideBarConfirm textarea {
  height: 182px; }

.SidebarContentWrapper.isLoading .SideBarLoadingBlock {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /* margin: auto; */
  text-align: center;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  z-index: 2; }

.SidebarContentWrapper.isLoading .SidebarBlocks,
.SidebarContentWrapper.isLoading .SideBarConfirm {
  opacity: .3; }

.SidebarBlocks {
  padding: 10px;
  position: absolute;
  overflow: scroll;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 127px;
  -webkit-transition: bottom .3s ease-in;
  transition: bottom .3s ease-in; }

.SidebarContentWrapper {
  position: relative;
  min-width: 200px;
  height: 100%;
  background: #FBFBFB;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid #CCCCCC; }
  .SidebarContentWrapper .eventBlock {
    border-radius: 3px;
    margin-top: 10px;
    border: 1px solid rgba(148, 145, 145, 0.322);
    background: white; }
    .SidebarContentWrapper .eventBlock .eventBlockHeader {
      position: relative;
      padding: 10px;
      overflow: hidden;
      border-bottom: 1px solid #CCCCCC; }
      .SidebarContentWrapper .eventBlock .eventBlockHeader .loopIcon {
        float: left;
        width: 20px;
        position: relative;
        top: -3px; }
      .SidebarContentWrapper .eventBlock .eventBlockHeader .eventDay {
        float: left;
        font-size: 12px;
        margin-left: 8px;
        margin-bottom: 3px;
        font-weight: bold; }
      .SidebarContentWrapper .eventBlock .eventBlockHeader .eventTime {
        font-size: 12px;
        margin-left: 8px;
        float: left; }
      .SidebarContentWrapper .eventBlock .eventBlockHeader .eventBlockDelete {
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        width: 20px;
        background: transparent;
        right: 4px;
        color: grey;
        top: 8px;
        cursor: pointer;
        -webkit-transition: color 0.3s ease-in, background 0.3s ease-in;
        transition: color 0.3s ease-in, background 0.3s ease-in; }
        .SidebarContentWrapper .eventBlock .eventBlockHeader .eventBlockDelete svg {
          -webkit-transition: color 0.3s ease-in, background 0.3s ease-in;
          transition: color 0.3s ease-in, background 0.3s ease-in;
          width: 20px;
          fill: #CCCCCC; }
          .SidebarContentWrapper .eventBlock .eventBlockHeader .eventBlockDelete svg:hover {
            fill: black; }
    .SidebarContentWrapper .eventBlock .eventBlockDateRange {
      padding-top: 3px;
      font-size: 14px;
      font-weight: bold; }
    .SidebarContentWrapper .eventBlock .eventBlockSpecificDates {
      padding-top: 5px;
      font-size: 12px;
      padding-bottom: 5px; }
      .SidebarContentWrapper .eventBlock .eventBlockSpecificDates .eventBlockOneDate {
        clear: both;
        overflow: hidden;
        padding-left: 10px;
        padding-top: 3px;
        text-decoration: line-through; }
        .SidebarContentWrapper .eventBlock .eventBlockSpecificDates .eventBlockOneDate svg {
          float: left;
          width: 20px; }
        .SidebarContentWrapper .eventBlock .eventBlockSpecificDates .eventBlockOneDate.busy {
          opacity: .3; }
          .SidebarContentWrapper .eventBlock .eventBlockSpecificDates .eventBlockOneDate.busy .eventBlockDate {
            text-decoration: line-through; }
        .SidebarContentWrapper .eventBlock .eventBlockSpecificDates .eventBlockOneDate .eventBlockDate {
          float: left;
          color: black;
          padding-top: 4px;
          padding-left: 4px; }
        .SidebarContentWrapper .eventBlock .eventBlockSpecificDates .eventBlockOneDate.available {
          text-decoration: none;
          color: green;
          font-weight: normal; }

.fc-day-grid-event.selection-next {
  background: -webkit-repeating-linear-gradient(135deg, white, white 5px, rgba(206, 155, 108, 0.3) 5px, rgba(206, 155, 108, 0.3) 10px);
  background: repeating-linear-gradient(-45deg, white, white 5px, rgba(206, 155, 108, 0.3) 5px, rgba(206, 155, 108, 0.3) 10px); }

.fc-button-group button {
  -webkit-transition: color 0.5s ease-in, background 0.5s ease-in;
  transition: color 0.5s ease-in, background 0.5s ease-in; }

.fc-day-grid-event.selection-start {
  color: white;
  background-color: #CE9B6C;
  -webkit-transition: color 0.5s ease-in, background 0.5s ease-in;
  transition: color 0.5s ease-in, background 0.5s ease-in; }
  .fc-day-grid-event.selection-start:hover {
    color: black; }

.fc-view-container {
  background-color: #FBFBFB;
  color: #333; }

.fc-view > table {
  z-index: 0; }

.fc-row.fc-widget-header {
  border-bottom: 1px solid #ececec5e; }
  .fc-row.fc-widget-header .fc-day-header {
    font-size: 12px;
    font-weight: 600;
    color: rgba(45, 45, 45, 0.368627); }

.fc-axis {
  color: rgba(45, 45, 45, 0.368627);
  font-size: 0.9em; }

.fc-state-default {
  text-shadow: none;
  box-shadow: none;
  background-image: none;
  background-color: white;
  border-color: white; }

.fc-button {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.1em;
  border: 0px;
  border-radius: 3px;
  outline: none; }
  .fc-button:hover, .fc-button:visited, .fc-button:active, .fc-button:focus {
    outline: none;
    border: 0px;
    background-color: transparent; }

.fc-content-skeleton {
  border-top: 1px solid #ddd; }

.fc .fc-toolbar {
  padding: 0px;
  margin-bottom: 0;
  border-bottom: 1px solid #ececec5e;
  min-height: 48px; }
  .fc .fc-toolbar > * > button {
    padding: 15px 17px;
    height: auto;
    outline: 0;
    margin-left: 0;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
    opacity: 0.3; }
    .fc .fc-toolbar > * > button:hover {
      opacity: 1; }
    .fc .fc-toolbar > * > button.fc-state-disabled {
      -webkit-transition: opacity 0s;
      transition: opacity 0s;
      opacity: 0; }
    .fc .fc-toolbar > * > button.fc-prev-button {
      padding-right: 8px; }
    .fc .fc-toolbar > * > button.fc-next-button {
      padding-left: 8px; }
    .fc .fc-toolbar > * > button .fc-icon {
      font-size: 1.1em;
      background-color: #2c3e50; }
  .fc .fc-toolbar > .fc-right > button.fc-today-button {
    padding: 8px;
    font-size: 11px;
    color: white;
    background-color: #CE9B6C;
    opacity: 1; }
    .fc .fc-toolbar > .fc-right > button.fc-today-button:hover {
      background: black; }
  .fc .fc-toolbar .fc-button-group {
    margin-right: 10px;
    color: white; }
  .fc .fc-toolbar .fc-button-group > .fc-button:not(:last-child) {
    padding: 8px;
    font-size: 11px;
    border-right: 2px solid white;
    color: white;
    background-color: #CE9B6C;
    outline: none !important; }
    .fc .fc-toolbar .fc-button-group > .fc-button:not(:last-child):active, .fc .fc-toolbar .fc-button-group > .fc-button:not(:last-child):visited {
      border-right: 2px solid white; }
    .fc .fc-toolbar .fc-button-group > .fc-button:not(:last-child):hover {
      background: black; }
  .fc .fc-toolbar .fc-button-group > .fc-button:not(:first-child) {
    padding: 8px;
    font-size: 11px;
    color: white;
    background-color: #CE9B6C; }
    .fc .fc-toolbar .fc-button-group > .fc-button:not(:first-child):active, .fc .fc-toolbar .fc-button-group > .fc-button:not(:first-child):visited {
      border-right: 2px solid grey;
      background: black; }
    .fc .fc-toolbar .fc-button-group > .fc-button:not(:first-child):hover {
      background: black; }
  .fc .fc-toolbar > .fc-right h2 {
    font-size: 13px;
    padding: 15px 0px 15px 20px;
    color: #333;
    font-weight: 600; }

.fc-unthemed td.fc-today {
  background: white; }

.fc-body > tr > .fc-widget-content,
.fc-head > tr > .fc-widget-header {
  border: 0 !important; }

.fc th {
  border-color: white;
  padding: 5px; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header {
  background-color: transparent; }

.empty-calendar .fc-event {
  opacity: 0; }

.fc-event {
  -webkit-transition: color 0.2s ease, border-color 0.2s ease, opacity 0.6s ease, box-shadow 0.2s ease;
  transition: color 0.2s ease, border-color 0.2s ease, opacity 0.6s ease, box-shadow 0.2s ease;
  border: none;
  border-left: 2px solid rgba(147, 147, 147, 0.368627);
  padding: 3px;
  background-color: white;
  border-radius: 3px;
  color: #333;
  margin: 1px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  margin-bottom: 2px;
  opacity: 1; }
  .fc-event:hover, .fc-event-clicked {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-left: 3px solid #CE9B6C;
    color: #CE9B6C;
    font-weight: 600;
    padding-left: 2px; }
  .fc-event .fc-content {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    overflow: visible; }
  .fc-event:hover .fc-content {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }
  .fc-event .fc-bg {
    opacity: 0; }

.fc-day-grid-event {
  padding: 13px 15px;
  margin: 1px 0 3px;
  min-height: 34px; }
  .fc-day-grid-event:hover, .fc-day-grid-event-clicked {
    padding-left: 14px; }
  .fc-day-grid-event .fc-time {
    font-size: 12px;
    font-weight: 500; }
  .fc-day-grid-event .fc-title {
    padding: 0 5px 5px;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: -4px;
    top: 17px; }
  .fc-day-grid-event:hover .fc-time,
  .fc-day-grid-event-clicked .fc-time,
  .fc-day-grid-event:hover .fc-title,
  .fc-day-grid-event-clicked .fc-title {
    font-weight: 600; }

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: none; }

.fc-time-grid .fc-slats td {
  border-top-color: #FBFBFB; }
  .fc-time-grid .fc-slats td.fc-axis {
    border-top-color: #ececec5e; }

.fc-time-grid-event.fc-short .fc-content {
  font-size: 0.7em;
  line-height: 0.2em; }

.fc-time-grid-event.fc-short .fc-time:after {
  content: ''; }

.fc-time-grid-event .fc-time {
  font-size: 1.1em;
  padding: 5px; }

.fc-time-grid-event .fc-title {
  padding: 0 5px 5px;
  font-weight: bold; }

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #ececec5e; }

.fc-agendaMonthly-view .fc-event {
  color: white; }

.fc-now-indicator {
  border-color: rgba(255, 0, 0, 0.5); }

.fc-unthemed .fc-basic-view .fc-scroller {
  padding: 5px 15px; }

.fc-unthemed .fc-basic-view .fc-content-skeleton {
  border-top: 0px; }

.fc-unthemed .fc-list-view .fc-scroller {
  padding: 0px 15px; }

.fc-list-view {
  border-width: 0px; }

.fc-list-table {
  width: 80%;
  max-width: 400px;
  margin: 0 auto 30px auto; }

.fc-unthemed .fc-list-heading td {
  background: transparent;
  border-color: transparent;
  font-size: 1.3em;
  line-height: 1em;
  padding: 20px 19px 15px 19px;
  font-weight: 500;
  color: #CE9B6C; }
  .fc-unthemed .fc-list-heading td .fc-list-heading-alt {
    color: rgba(45, 45, 45, 0.368627); }

.is-small .fc-unthemed .fc-list-heading td {
  font-size: 1.1em; }

.fc-unthemed .fc-list-item:hover td {
  background-color: transparent; }

.fc-list-item {
  display: block;
  -webkit-transition: color 0.2s ease, border-color 0.2s ease, opacity 0.6s ease, box-shadow 0.2s ease;
  transition: color 0.2s ease, border-color 0.2s ease, opacity 0.6s ease, box-shadow 0.2s ease;
  border: none;
  border-left: 2px solid rgba(147, 147, 147, 0.368627);
  background-color: #fff;
  border-radius: 3px;
  color: #333;
  margin: 1px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 12px; }
  .fc-list-item:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-left: 3px solid #CE9B6C;
    color: #CE9B6C;
    font-weight: 600;
    padding-left: 2px; }
  .fc-list-item td {
    background: transparent;
    border-color: transparent;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease; }
  .fc-list-item:hover td {
    background: transparent;
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }
  .fc-list-item .fc-list-item-marker {
    display: none; }
  .fc-list-item .fc-list-item-time {
    padding-right: 0px;
    min-width: 110px; }
  .fc-list-item .fc-list-item-title a {
    font-weight: 600; }

.fc-unthemed .fc-list-empty {
  background-color: transparent; }

.fc-button-primary:focus {
  box-shadow: none; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fc-toolbar,
.fc-view-container {
  z-index: 0; }

.fc-day-grid-event {
  border-color: transparent;
  padding: 4px;
  margin: 2px;
  font-weight: bold; }

.fc-day-grid-event .fc-time {
  font-weight: bold; }

#sidebar-overlay {
  display: none; }

.bodyWrapper {
  clear: both; }

.loginBlock {
  font-size: 12px;
  font-weight: bold;
  float: right;
  margin: 10px 16px 0 15px; }

.primarySiloButton {
  line-height: 1.5;
  border-radius: 0.25em;
  padding: 0.5em;
  color: #CE9B6C;
  background-color: transparent;
  border: 1px solid #CE9B6C;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  border-radius: 3px;
  outline: 0;
  font-size: 12px;
  font-weight: bold; }
  .primarySiloButton--full {
    text-align: center;
    width: 100%; }

