// Colors
$textColor: #333;
$textLightColor: white;
$textLighterColor: white;
$bgColor: #FBFBFB;
$sideBarBorderColor: #CCCCCC;
$borderColor: #ececec5e;
$subtleColor: darken($borderColor, 75%);
$primaryColor: #CE9B6C;
$primaryDarkColor: darken($primaryColor, 10%);
$errorColor: #D83B46;
$successColor: #46CE92;
$ribbonColor: #ffb46e;
$ribbonDarkColor: darken($ribbonColor, 15%);

// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
$fontFamily: 'Open Sans',
Helvetica,
Tahoma,
Arial,
sans-serif;