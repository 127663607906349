@import 'variables';

// Classes

.SideBarConfirm {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  text-align: center;
  border-top: 1px solid $sideBarBorderColor;

  .SideBarButtonWrapper {
    background: $bgColor;
    border-top: 1px solid $sideBarBorderColor;
    padding: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .SideBarTextAreaWrapper {
    padding: 5px;

  }

  textarea {
    transition: height .3s ease-in;
    margin: 0;
    font-size: 13px;
    height: 60px;
    resize: none;
    width: 190px;
    outline: none;
    border: 0;
  }
}

div[role='navigation']>div {
  overflow: hidden;
}

.SidebarContentWrapper.focusTextarea {
  .SidebarBlocks {
    bottom: 250px;
  }

  .SideBarConfirm textarea {
    height: 182px;
  }

}

.SidebarContentWrapper.isLoading {
  .SideBarLoadingBlock {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    /* margin: auto; */
    text-align: center;
    bottom: 0;
    transform: translateY(50%);
    z-index: 2;
  }

  .SidebarBlocks,
  .SideBarConfirm {
    opacity: .3;
  }
}

.SidebarBlocks {
  padding: 10px;
  position: absolute;
  overflow: scroll;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 127px;
  transition: bottom .3s ease-in;
}

.SidebarContentWrapper {
  position: relative;
  min-width: 200px;
  height: 100%;
  background: $bgColor;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid $sideBarBorderColor;

  .eventBlock {
    border-radius: 3px;
    margin-top: 10px;
    border: 1px solid rgba(148, 145, 145, 0.322);
    background: $textLightColor;

    .eventBlockHeader {
      position: relative;
      padding: 10px;
      overflow: hidden;
      border-bottom: 1px solid $sideBarBorderColor;

      .loopIcon {
        float: left;
        width: 20px;
        position: relative;
        top: -3px;
      }

      .eventDay {
        float: left;
        font-size: 12px;
        margin-left: 8px;
        margin-bottom: 3px;
        font-weight: bold;
      }

      .eventTime {
        font-size: 12px;
        margin-left: 8px;
        float: left;
      }

      .eventBlockDelete {
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        width: 20px;
        background: transparent;
        right: 4px;
        color: grey;
        top: 8px;
        cursor: pointer;
        transition: color 0.3s ease-in, background 0.3s ease-in;

        svg {
          transition: color 0.3s ease-in, background 0.3s ease-in;
          width: 20px;
          fill: $sideBarBorderColor;

          &:hover {
            fill: black;
          }
        }
      }
    }

    .eventBlockDateRange {
      padding-top: 3px;
      font-size: 14px;
      font-weight: bold;
    }

    .eventBlockSpecificDates {
      padding-top: 5px;
      font-size: 12px;
      padding-bottom: 5px;

      .eventBlockOneDate {
        clear: both;
        overflow: hidden;
        padding-left: 10px;

        svg {
          float: left;
          width: 20px;

        }

        &.busy {
          opacity: .3;

          .eventBlockDate {
            //color: $sideBarBorderColor;
            text-decoration: line-through;
          }
        }

        .eventBlockDate {
          float: left;
          color: black;
          padding-top: 4px;
          padding-left: 4px;
        }

        padding-top: 3px;
        text-decoration: line-through;

        &.available {
          text-decoration: none;
          color: green;
          font-weight: normal;
        }
      }
    }
  }
}

.fc-day-grid-event.selection-next {
  background: repeating-linear-gradient(-45deg,
      $textLightColor,
      $textLightColor 5px,
      adjust-color($primaryColor, $alpha: -0.7) 5px,
      adjust-color($primaryColor, $alpha: -0.7) 10px);
}

.fc-button-group button {
  transition: color 0.5s ease-in, background 0.5s ease-in;
}

.fc-day-grid-event.selection-start {
  color: $textLightColor;
  background-color: $primaryColor;
  transition: color 0.5s ease-in, background 0.5s ease-in;

  &:hover {
    color: black;
  }
}

.fc-view-container {
  background-color: $bgColor;
  color: $textColor;
}

.fc-view>table {
  z-index: 0;
}

.fc-row.fc-widget-header {
  border-bottom: 1px solid $borderColor;

  .fc-day-header {
    font-size: 12px;
    font-weight: 600;
    color: $subtleColor;
  }
}

.fc-axis {
  color: $subtleColor;
  font-size: 0.9em;
}

.fc-state-default {
  text-shadow: none;
  box-shadow: none;
  background-image: none;
  background-color: white;
  border-color: white;
}

.fc-button {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.1em;
  border: 0px;
  border-radius: 3px;
  outline: none;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    outline: none;
    border: 0px;
    background-color: transparent;
  }
}

.fc-content-skeleton {
  border-top: 1px solid #ddd;
}

.fc .fc-toolbar {
  padding: 0px;
  margin-bottom: 0;
  border-bottom: 1px solid $borderColor;
  min-height: 48px;

  >*>button {
    padding: 15px 17px;
    height: auto;
    outline: 0;
    margin-left: 0;
    transition: opacity 0.2s ease;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }

    &.fc-state-disabled {
      transition: opacity 0s;
      opacity: 0;
    }

    &.fc-prev-button {
      padding-right: 8px;
    }

    &.fc-next-button {
      padding-left: 8px;
    }

    .fc-icon {
      font-size: 1.1em;
      background-color: #2c3e50;
    }
  }

  >.fc-right>button.fc-today-button {
    padding: 8px;
    font-size: 11px;
    color: $textLightColor;
    background-color: $primaryColor;
    opacity: 1;

    &:hover {
      background: black;
    }
  }

  .fc-button-group {
    margin-right: 10px;
    color: $textLightColor;
    //background-color: $primaryColor;
  }

  .fc-button-group>.fc-button:not(:last-child) {
    padding: 8px;
    font-size: 11px;
    border-right: 2px solid $textLightColor;
    color: $textLightColor;
    background-color: $primaryColor;
    outline: none !important;


    &:active,
    &:visited {
      border-right: 2px solid $textLightColor;
      //   background: black;
    }

    &:hover {
      background: black;
    }
  }

  .fc-button-group>.fc-button:not(:first-child) {
    padding: 8px;
    font-size: 11px;
    color: $textLightColor;
    background-color: $primaryColor;

    &:active,
    &:visited {
      border-right: 2px solid grey;
      background: black;
    }

    &:hover {
      background: black;
    }
  }

  >.fc-right h2 {
    font-size: 13px;
    padding: 15px 0px 15px 20px;
    color: $textColor;
    font-weight: 600;
  }
}

.fc-unthemed td.fc-today {
  background: white;
}

.fc-body>tr>.fc-widget-content,
.fc-head>tr>.fc-widget-header {
  border: 0 !important;
}

.fc th {
  border-color: white;
  padding: 5px;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header {
  background-color: transparent;
}

.empty-calendar .fc-event {
  opacity: 0;
}

.fc-event {
  transition: color 0.2s ease, border-color 0.2s ease, opacity 0.6s ease,
    box-shadow 0.2s ease;
  border: none;
  border-left: 2px solid darken($borderColor, 35%);
  padding: 3px;
  background-color: white;
  border-radius: 3px;
  color: $textColor;
  margin: 1px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  margin-bottom: 2px;
  opacity: 1;

  &:hover,
  &-clicked {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-left: 3px solid $primaryColor;
    color: $primaryColor;
    font-weight: 600;
    padding-left: 2px;
  }

  .fc-content {
    transform: translateX(0);
    transition: transform 0.2s ease;
    overflow: visible;
  }

  &:hover .fc-content {
    transform: translateX(2px);
  }

  .fc-bg {
    opacity: 0;
  }
}

.fc-day-grid-event {
  padding: 13px 15px;
  margin: 1px 0 3px;
  min-height: 34px;

  &:hover,
  &-clicked {
    padding-left: 14px;
  }

  .fc-time {
    font-size: 12px;
    font-weight: 500;
  }

  .fc-title {
    padding: 0 5px 5px;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: -4px;
    top: 17px;
  }

  &:hover .fc-time,
  &-clicked .fc-time,
  &:hover .fc-title,
  &-clicked .fc-title {
    font-weight: 600;
  }
}

.fc-time-grid .fc-slats {
  .fc-minor td {
    border-top-style: none;
  }

  td {
    border-top-color: $bgColor;

    &.fc-axis {
      border-top-color: $borderColor;
    }
  }
}

.fc-time-grid-event {
  &.fc-short {
    .fc-content {
      font-size: 0.7em;
      line-height: 0.2em;
    }

    .fc-time:after {
      content: '';
    }
  }

  .fc-time {
    font-size: 1.1em;
    padding: 5px;
  }

  .fc-title {
    padding: 0 5px 5px;
    font-weight: bold;
  }
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: $borderColor;
}

.fc-agendaMonthly-view .fc-event {
  color: white;
}

.fc-now-indicator {
  border-color: rgba(255, 0, 0, 0.5);
}

.fc-unthemed .fc-basic-view {
  .fc-scroller {
    padding: 5px 15px;
  }

  .fc-content-skeleton {
    border-top: 0px;
  }
}

// List view

.fc-unthemed .fc-list-view .fc-scroller {
  padding: 0px 15px;
}

.fc-list-view {
  border-width: 0px;
}

.fc-list-table {
  width: 80%;
  max-width: 400px;
  margin: 0 auto 30px auto;
}

.fc-unthemed .fc-list-heading td {
  background: transparent;
  border-color: transparent;
  font-size: 1.3em;
  line-height: 1em;
  padding: 20px 19px 15px 19px;
  font-weight: 500;
  color: $primaryColor;

  .fc-list-heading-alt {
    color: $subtleColor;
  }
}

.is-small .fc-unthemed .fc-list-heading td {
  font-size: 1.1em;
}

.fc-unthemed .fc-list-item:hover td {
  background-color: transparent;
}

.fc-list-item {
  display: block;
  transition: color 0.2s ease, border-color 0.2s ease, opacity 0.6s ease,
    box-shadow 0.2s ease;
  border: none;
  border-left: 2px solid darken($borderColor, 35%);
  background-color: #fff;
  border-radius: 3px;
  color: #333;
  margin: 1px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 12px;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-left: 3px solid $primaryColor;
    color: $primaryColor;
    font-weight: 600;
    padding-left: 2px;
  }

  td {
    background: transparent;
    border-color: transparent;
    transform: translateX(0);
    transition: transform 0.2s ease;
  }

  &:hover td {
    background: transparent;
    transform: translateX(2px);
  }

  .fc-list-item-marker {
    display: none;
  }

  .fc-list-item-time {
    padding-right: 0px;
    min-width: 110px;
  }

  .fc-list-item-title a {
    font-weight: 600;
  }
}

.fc-unthemed .fc-list-empty {
  background-color: transparent;
}