@import 'styles/variables';
@import 'styles/utils';

body {
  background: #f8f7f5;
}

.contentBlock {

  // background: white;
  .waitlistBlock {
    float: left;
    padding-top: 16px;
    padding-left: 15px;
    font-size: 12px;

    a {
      padding: 7px;
      border: 1px solid rgba(0, 0, 0, .3);
      border-radius: 4px;
      background: white;
      color: black;
      text-decoration: none;

      b {
        color: $primaryColor;
      }
    }
  }
}

div[class*='-control'] {
  border-color: $sideBarBorderColor;
  box-shadow: 0 0 0 0px $primaryColor;
}

div[class*='-control']:hover {
  border-color: $primaryColor;
  box-shadow: 0 0 0 1px $primaryColor;
}

div[class*='-control']:hover {
  border-color: $primaryColor;
  box-shadow: 0 0 0 1px $primaryColor;
}

div[class*='-option']:hover {
  background-color: $primaryColor;
}

// div[class*='-MenuList'] div[class*='-option'] {
//   background-color: $sideBarBorderColor;
// }

.unlogged {
  .logoWrapper {
    text-align: center;

    margin-top: 60px;
    margin-bottom: 30px;

    img {
      width: 150px;
    }
  }

  .loginDetails {
    text-align: center;
    margin: auto;
    font-size: 12px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #aaa;
  }

  .firebaseui-container {
    padding: 40px;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  }
}

.mdl-card {
  .is-focused label:after {
    background-color: $primaryColor !important;
  }

  .firebaseui-id-secondary-link {
    color: $primaryColor !important;
  }

  // TODO: FIX ME
  .firebaseui-id-submit {
    background-color: $primaryColor !important;
  }
}

.bodyWrapper {
  .loading {
    text-align: center;
    margin: auto;
    margin-top: 30px;
    font-weight: bold;
    color: $subtleColor;
    font-size: 12px;
  }

  .iconWrapper {
    margin-top: 20px;
    paddin-top: 20px;
  }

  .details {
    margin-top: 30px;
    line-height: 18px;
    padding-top: 30px;
    max-width: 300px;
    margin: auto;
  }
}

.rotatesvg svg {
  stroke-dasharray: 0%;
  stroke-dashoffset: 100%;

  transform: rotateY(0turn);
  -webkit-transform: 50% 50%;
  -ms-transform: 50% 50%;
  transform: 50% 50%;
  animation: flip 2s ease-in infinite;
  margin: auto;

  // .iconpath {
  //   stroke: rgba(0, 0, 0, 0);
  //   stroke-dasharray: 5px;
  //   stroke-width: 1px;
  //   stroke-dashoffset: 10px;
  //   //fill: transparent;

  //   animation: dash 20s ease-in infinite;
  // }

  &.done {
    animation: none;
  }
}

@keyframes dash {
  to {
    stroke: rgba(0, 0, 0, 1);
    stroke-dasharray: 0%;
    stroke-dashoffset: 0;
  }
}

@keyframes flip {
  to {
    transform: rotateY(1turn);
  }
}